import React, { useContext, useState } from 'react';
import { Button } from '../ui/button';
import { Pencil, Trash2 } from 'lucide-react';
import UpdateOfficeModal from './update-office-modal.component';
import useConfirm from '../ui/confirm-dialog';
import { Office } from '../../types';
import { useDeleteOffice } from '../../utils/hooks/api/offices/useDeleteOffice';

function OfficesTableRow({ office }: { office: Office }) {
  const [updateOpen, setUpdateOpen] = useState(false);
  const { confirm } = useConfirm();
  const { mutate: mutateDeleteOffice } = useDeleteOffice();

  const handleUpdateModal = () => {
    setUpdateOpen(!updateOpen);
  };

  const deleteOffice = async function () {
    const hasConfirmed = await confirm({
      destructiveLabel: 'Delete',
      title: 'Delete office',
      description: 'Are you sure you want to delete this office?',
      cancelLabel: 'Cancel',
    });
    if (hasConfirmed) {
      mutateDeleteOffice(office.id, { onSuccess: () => {} });
    }
  };

  return (
    <>
      <div className='flex flex-col items-center flex-1 p-4 rounded shadow bg-grey-100'>
        <h4>{office.abbreviation}</h4>
        <p>
          {office.city}, {office.country}
        </p>
        <div className='flex flex-row items-center gap-4'>
          <Button
            variant='link'
            className='space-x-2'
            onClick={handleUpdateModal}
          >
            <Pencil className='w-5 h-5' />
            <p>Edit</p>
          </Button>
          <Button variant='link' className='space-x-2' onClick={deleteOffice}>
            <Trash2 className='w-5 h-5 ' />
            <p>Delete</p>
          </Button>
        </div>
      </div>

      <UpdateOfficeModal
        open={updateOpen}
        handleClose={handleUpdateModal}
        office={office}
      />
    </>
  );
}

export default OfficesTableRow;
