import toast from 'react-hot-toast';
import { axios } from './axios';
import { RawConsultant, RawStatus, Status } from '../../types';
import { getToken } from './helper';
import { AxiosError } from 'axios';

const StatusService = {
  list: async function () {
    return axios
      .get<Status[]>('/colors', {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  update: (updateParams: RawStatus & { id: string }) => {
    return axios
      .put<Status>(`/color/${updateParams.id}`, updateParams, {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  create: (createParams: RawStatus) => {
    return axios
      .post<Status>(`/color`, createParams, {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  delete: (statusId: string) => {
    return axios
      .delete<unknown>(`/color/${statusId}`, {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
};

export default StatusService;
