import { AxiosStatic, default as axiosPreConfig } from 'axios';

export const getAxiosInstance = (token = '', interceptor: any) => {
  axiosPreConfig.defaults.baseURL =
    process.env.REACT_APP_API_URI || 'http://localhost:5050/api';
  axiosPreConfig.defaults.headers.authorization = token;
  addInterceptors(axiosPreConfig, interceptor);
  return axiosPreConfig;
};

const axios = axiosPreConfig.create({
  timeout: 30000,
  baseURL: process.env.REACT_APP_API_URI || 'http://localhost:5050/api',
});

export { axios };

const addInterceptors = (axios: AxiosStatic, interceptor: any) => {
  axios.interceptors.response.use(interceptor.success, interceptor.error);
};
