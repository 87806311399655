export const notAuthorizedInterceptor = (error_handler) => {
    return {
        'success': (response) => {
        return response
        },
        'error': (error) => {
            if (error.response.status === 401) {
                error_handler()
            }
            return Promise.reject(error)
        }
    }

}