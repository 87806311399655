import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { Input } from '../ui/input';

import { Button } from '../ui/button';
import { useUpdateStatus } from '../../utils/hooks/api/statuses/useUpdateStatus';
import { RawStatus, Status } from '../../types';

function UpdateColorModal({
  open,
  handleClose,
  status,
}: {
  open: boolean;
  handleClose: () => void;
  status: Status;
}) {
  const [updateStatusParams, setUpdateStatusParams] = useState<RawStatus>({
    name: status.name,
    RGBCode: status.RGBCode,
    description: status.description,
  });
  const { mutate: mutateUpdateStatus } = useUpdateStatus();
  const updateStatus = () => {
    mutateUpdateStatus(
      { id: status.id, ...updateStatusParams },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };
  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className='max-w-screen-sm '>
        <DialogHeader>
          <DialogTitle>Update status</DialogTitle>
        </DialogHeader>

        <div className='flex flex-col gap-4'>
          <Input
            type='text'
            value={updateStatusParams.name}
            placeholder='Name'
            onChange={(e) =>
              setUpdateStatusParams({
                ...updateStatusParams,
                name: e.target.value,
              })
            }
          />
          <Input
            type='text'
            value={updateStatusParams.description}
            onChange={(e) =>
              setUpdateStatusParams({
                ...updateStatusParams,
                description: e.target.value,
              })
            }
          />
          <Input
            type='color'
            value={updateStatusParams.RGBCode}
            onChange={(e) =>
              setUpdateStatusParams({
                ...updateStatusParams,
                RGBCode: e.target.value,
              })
            }
          />

          <div className='flex items-center justify-center'>
            <Button onClick={updateStatus}>Update status</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateColorModal;
