import { AxiosError } from 'axios';
import { axios } from './axios';
import toast from 'react-hot-toast';
import { RawRole, Role } from '../../types';
import { getToken } from './helper';

const RoleService = {
  list: async function () {
    return axios
      .get<Role[]>('/roles', { headers: { authorization: getToken() } })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  add: async function (roleData: RawRole) {
    return axios
      .post<RawRole>('/roles', roleData, {
        headers: { Authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  delete: async function (roleId: string) {
    return axios
      .delete<string>(`/roles/${roleId}`, {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
};

export default RoleService;
