import toast from 'react-hot-toast';
import { axios } from './axios';
import { Office, RawOffice } from '../../types';
import { getToken } from './helper';
import { AxiosError } from 'axios';

const OfficeService = {
  list: async function () {
    return axios
      .get<Office[]>('/offices', {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  create: (officeParams: any) => {
    return axios
      .post<Office>('/office', officeParams, {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  update: (officeParams: RawOffice & { id: string }) => {
    return axios
      .put<Office>(`/office/${officeParams.id}`, officeParams, {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  delete: (officeId: string) => {
    return axios
      .delete<unknown>(`/office/${officeId}`, {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
};

export default OfficeService;
