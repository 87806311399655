import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { ConsultantAvailability } from '../../../../types';
import ConsultantService from '../../../services/consultant-service';

export const useAvailabilityOverview = () => {
  const {
    data: availabilityOverview,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery<ConsultantAvailability, AxiosError>({
    queryKey: ['consultant-availability'],
    queryFn: ConsultantService.getAvailability,
  });

  return {
    availabilityOverview,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
