import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import pyyneLogo from '../../assets/images/logo-pyyne.jpeg';
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
import { Button } from '../ui/button';

function SendPasswordResetLinkComponent({ SendPasswordReset, error }) {
  const [passwordResetInfo, setPasswordResetInfo] = useState({ email: '' });

  const { getAxios } = useContext(CurrentUserContext);

  const submitHandler = (e) => {
    e.preventDefault();
    SendPasswordReset(getAxios(), passwordResetInfo);
  };
  return (
    <div className='login-signup-form-container'>
      <form onSubmit={submitHandler}>
        <img src={pyyneLogo} alt='PYYNE Logo' />
        <div className='form-input-container'>
          <input
            type='email'
            name='email'
            placeholder='Email'
            id='email'
            onChange={(e) =>
              setPasswordResetInfo({
                ...passwordResetInfo,
                email: e.target.value,
              })
            }
            value={passwordResetInfo.email}
          />
        </div>
        <div className='form-submit-container'>
          <Button type='submit'>Send Password Reset</Button>
        </div>
        <Button variant={'link'} to='/login'>
          Have an account?
        </Button>
      </form>
    </div>
  );
}

export default SendPasswordResetLinkComponent;
