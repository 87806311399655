import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { Input } from '../ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Button } from '../ui/button';

function InviteUserModal({
  open,
  handleClose,
  inviteParams,
  setInviteParams,
  onSubmit,
}: {
  open: boolean;
  handleClose: () => void;
  inviteParams: { email: string; role: string };
  setInviteParams: (data: { email: string; role: string }) => void;
  onSubmit: () => void;
}) {
  const roles = [
    { value: 'admin', label: 'Admin' },
    { value: 'consultant', label: 'Consultant' },
    { value: 'salesperson', label: 'Salesperson' },
  ];

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className='max-w-screen-sm '>
        <DialogHeader>
          <DialogTitle>Invite User</DialogTitle>
        </DialogHeader>

        <div className='flex flex-col gap-4'>
          {/* TODO Should be a form with validation instead of passing things like this -> React form hook 
                Requires too many changes for this vidual revamp
            */}
          <Input
            type='text'
            value={inviteParams.email}
            placeholder='Email'
            onChange={(e) =>
              setInviteParams({ ...inviteParams, email: e.target.value })
            }
          />
          <Select
            onValueChange={(option) =>
              setInviteParams({ ...inviteParams, role: option })
            }
          >
            <SelectTrigger>
              <SelectValue placeholder='Select a role' />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {roles.map((role) => {
                  return (
                    <SelectItem key={role.value} value={role.value}>
                      {role.label}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>

          <div className='flex items-center justify-center'>
            <Button onClick={onSubmit}>Invite</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default InviteUserModal;
