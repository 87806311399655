import { createContext, useEffect, useState } from 'react'
import { getAxiosInstance } from '../services/axios'
import { notAuthorizedInterceptor } from '../services/axios-interceptors'

export const CurrentUserContext = createContext({
    isAuthenticated: null,
    isAdmin: null,
    getCurrentUser: () => { },
    login: async (userData) => { },
    logout: async () => { },
    getAxios: () => { }
})

export const CurrentUserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)

    useEffect(() => {
        const storagedUser = localStorage.getItem('@App:user')
        if (storagedUser) {
            setCurrentUser(JSON.parse(storagedUser))
        }
        const user = getCurrentUser()
        const token = user ? user.token : ''
        return getAxiosInstance(token, notAuthorizedInterceptor(logout))
    }, [])

    const getAxios = () => {
        const user = getCurrentUser()
        const token = user ? user.token : ''
        return getAxiosInstance(token, notAuthorizedInterceptor(logout))
    }

    const login = (userData) => {
        setCurrentUser(userData)
        localStorage.setItem('@App:user', JSON.stringify(userData))
        return {
            success: true,
            message: 'deu bom'
        }
    }

    const logout = () => {
        localStorage.clear()
        setCurrentUser(null)
        return {
            status: true
        }
    }

    const getCurrentUser = () => {
        if (!currentUser) {
            const response = localStorage.getItem('@App:user')
            if (!response) {
                return null
            }
            return JSON.parse(response)
        }
        return currentUser
    }
    return (
        <CurrentUserContext.Provider
            value={{
                isAuthenticated: Boolean(currentUser),
                getCurrentUser,
                login,
                logout,
                getAxios
            }}
        >
            {children}
        </CurrentUserContext.Provider>
    )
}
