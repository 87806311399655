import React, { useState } from 'react';
// @ts-ignore
import ManageUsersTableRowComponent from './manage-users-table-row-component';
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '../ui/table';

function ManageUsersTable({ filteredUsers, getUsers }: any) {
  const tableHeaders = [
    'Name',
    'Email',
    'Status',
    'Role',
    'Resend Email',
    'Actions',
  ];

  return (
    <Table>
      <TableHeader>
        <TableRow>
          {tableHeaders.map((header) => (
            <TableHead key={header}>{header}</TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {filteredUsers && filteredUsers.length
          ? filteredUsers.map((user: any, index: number) => {
              return (
                <ManageUsersTableRowComponent
                  user={user}
                  index={index}
                  getUsers={getUsers}
                />
              );
            })
          : 'No Users Found'}
      </TableBody>
    </Table>
  );
}

export default ManageUsersTable;
