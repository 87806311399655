import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import React, { useState } from 'react';

import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { availableLevels, cn } from '../../utils/helpers';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectItem,
  SelectContent,
  SelectGroup,
} from '../ui/select';
import { Checkbox } from '../ui/checkbox';
import { Consultant, RawConsultant } from '../../types';
import { useCreateConsultant } from '../../utils/hooks/api/consultants/useCreateConsultant';
import { useOffices } from '../../utils/hooks/api/offices/useOffices';
import ConsultantCardInfoUpdate from './consultant-card-info-update.component';
import { ConsultantContext } from '../../utils/contexts/consultant-context';

function EditContent({ handleModalOpen }: any) {
  return (
    <ConsultantCardInfoUpdate
      handleEditMode={handleModalOpen}
      handleModalOpen={handleModalOpen}
    />
  );
}

function CreateConsultantModal({
  open,
  handleModalOpen,
  hideTrigger,
  consultants,
}: {
  open: boolean;
  handleModalOpen: () => void;
  hideTrigger?: boolean;
  consultants?: Consultant[];
}) {
  const { mutate: createConsultant } = useCreateConsultant();
  const { parsedOffices } = useOffices();
  const [updateBlock, setUpdateBlock] = useState(false);
  const [createdConsultant, setCreatedConsultant] = useState<
    Consultant | undefined
  >();

  const [createConsultantParams, setCreateConsultantParams] =
    useState<RawConsultant>({
      name: '',
      level: '',
      location: '',
      firstEngagement: false,
    });

  const finalizeCreate = () => {
    createConsultant(createConsultantParams, {
      onSuccess: (results) => {
        setCreatedConsultant(results);
      },
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onOpenChange={() => {
          handleModalOpen();
          setCreatedConsultant(undefined);
        }}
      >
        {!hideTrigger && (
          <DialogTrigger>
            <Button asChild onClick={handleModalOpen}>
              Add Consultant
            </Button>
          </DialogTrigger>
        )}
        {/* after creating consultant - show page to update it */}
        {createdConsultant ? (
          <DialogContent className='max-w-[100vw] md:max-w-screen-lg max-h-[90vh] overflow-y-scroll'>
            <DialogHeader>
              <DialogTitle>Update Consultant</DialogTitle>
            </DialogHeader>
            <ConsultantContext.Provider
              key={createdConsultant.id}
              value={{
                consultant: createdConsultant,
                isConsultantUser: true,
              }}
            >
              <EditContent handleModalOpen={handleModalOpen} />
            </ConsultantContext.Provider>
          </DialogContent>
        ) : (
          <DialogContent className='max-w-screen-sm'>
            <DialogHeader>
              <DialogTitle>Add a new consultant</DialogTitle>
            </DialogHeader>
            <div className='flex flex-col gap-4 '>
              <div>
                <Input
                  type='text'
                  placeholder='Name'
                  value={createConsultantParams.name}
                  onChange={(e) =>
                    setCreateConsultantParams({
                      ...createConsultantParams,
                      name: e.target.value,
                    })
                  }
                />
              </div>
              <div className=''>
                <Select
                  onValueChange={(option) => {
                    setCreateConsultantParams({
                      ...createConsultantParams,
                      level: option,
                    });
                  }}
                >
                  <SelectTrigger className='col-span-4'>
                    <SelectValue placeholder='Select level' />
                  </SelectTrigger>

                  <SelectContent>
                    <SelectGroup>
                      {availableLevels.map((color) => {
                        return (
                          <SelectItem key={color.value} value={color.value}>
                            {color.label}
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className=''>
                <Select
                  onValueChange={(option) => {
                    setCreateConsultantParams({
                      ...createConsultantParams,
                      location: option,
                    });
                  }}
                >
                  <SelectTrigger className={cn('col-span-4')}>
                    <SelectValue placeholder='Select location' />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {parsedOffices?.map((option) => {
                        return (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className='flex flex-row items-center gap-2'>
                <Checkbox
                  id='firstEngagement'
                  defaultChecked={createConsultantParams.firstEngagement}
                  onCheckedChange={(checked) =>
                    setCreateConsultantParams({
                      ...createConsultantParams,
                      firstEngagement: !!checked.valueOf(),
                    })
                  }
                />
                <label
                  htmlFor='firstEngagement'
                  className='peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                >
                  First engagement?
                </label>
              </div>
            </div>
            <div className='flex items-center justify-center w-full'>
              <Button onClick={finalizeCreate}>Add</Button>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}

export default CreateConsultantModal;
