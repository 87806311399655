import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Office } from '../../../../types';
import OfficeService from '../../../services/office-service';
import { useEffect, useState } from 'react';

export const useOffices = () => {
  const {
    data: offices,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery<Office[], AxiosError>({
    queryKey: ['offices'],
    queryFn: OfficeService.list,
  });

  const [parsedOffices, setParsedOffices] =
    useState<{ value: string; label: string }[]>();
  useEffect(() => {
    if (!offices) {
      return;
    }
    setParsedOffices(
      offices?.map((value) => {
        return { value: value.abbreviation, label: value.city };
      })
    );
  }, [offices]);

  return {
    offices,
    parsedOffices,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
