import { useMutation, useQueryClient } from 'react-query';
import OfficeService from '../../../services/office-service';
import StatusService from '../../../services/status-service';

export const useCreateStatus = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation(
    StatusService.create,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['statuses']);
      },
    }
  );
  return { mutate, isLoading, isError, error };
};
