import React, { useContext } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { CurrentUserContext } from '../../utils/contexts/current-user.context';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { getCurrentUser } = useContext(CurrentUserContext);

  const isAuthenticated = getCurrentUser();
  if (isAuthenticated) {
    return <Navigate to='/consultants' />;
  }
  return <Component />;
};

export default AuthRoute;
