import React from 'react';
import { useScreenDetector } from '../../utils/hooks/useScreenDetector';
import { MobileSelect } from './mobile-select';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select';

import { ValueAndLabel } from '../../types';

interface ResponsiveSelectProps {
  onValueChange: (value: string) => void;
  value: string;
  options: ValueAndLabel[] | undefined;
  name: string;
  className?: string;
  useLabelAsSelected?: boolean;
}

export const ResponsiveSelect: React.FC<ResponsiveSelectProps> = ({
  onValueChange,
  value,
  options = [],
  name,
  className,
  useLabelAsSelected,
}) => {
  const { isMobile } = useScreenDetector();
  return (
    <>
      {isMobile ? (
        <MobileSelect
          selectName={`Select ${name}`}
          className={className}
          useLabelAsSelected={useLabelAsSelected}
          options={options}
          onSelectValue={onValueChange}
          value={value}
        />
      ) : (
        <Select onValueChange={onValueChange} defaultValue={value}>
          <SelectTrigger className={className}>
            <SelectValue placeholder={name} />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((level) => {
                return (
                  <SelectItem key={level.value} value={level.value}>
                    {level.label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      )}
    </>
  );
};
