import React, { useContext, useEffect, useReducer, useState } from 'react';
import { availableLevels } from '../utils/helpers';
import ConsultantCard from '../components/consultants-finder-page/consultant-card.component';
import CreateConsultantModal from '../components/consultants-finder-page/create-consultant-modal.component';
import { Trash2, Search, Plus, MoveUp, Info } from 'lucide-react';
// @ts-ignore
import { CurrentUserContext } from '../utils/contexts/current-user.context';
import DateHelper from '../utils/helpers/date.helper';
import { Combobox } from '../components/ui/combo-box';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { DateSelector } from '../components/ui/date-selector';
import { AvailableFilters } from '../types';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../components/ui/accordion';
import { Loader } from '../components/ui/loader';
import { useConsultants } from '../utils/hooks/api/consultants/useConsultants';
import { ConsultantContext } from '../utils/contexts/consultant-context';
import { useOffices } from '../utils/hooks/api/offices/useOffices';
import { useStatuses } from '../utils/hooks/api/statuses/useStatuses';
import { useRoles } from '../utils/hooks/api/roles/useRoles';
import { useDebounce } from '../utils/hooks/useDebounce';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '../components/ui/hover-card';
import { useScreenDetector } from '../utils/hooks/useScreenDetector';

function ConsultantsFinderPage() {
  // @ts-ignore
  const { getCurrentUser } = useContext(CurrentUserContext);
  const user = getCurrentUser();

  // Modal variables
  const [open, setOpen] = useState(false);
  const handleModalOpen = () => {
    setOpen(!open);
  };

  // TODO Might be worth to put state into url to allow easy sharing
  // Could also be placed in a use reducer
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const _updateSearch = (search: string) => {
    setSearchQuery(search);
  };
  const debouncedFunction = useDebounce(_updateSearch, 500);
  useEffect(() => {
    debouncedFunction(debouncedSearchQuery);
  }, [debouncedSearchQuery]);
  const [filters, setFilters] = useState<AvailableFilters>({
    filteredLevels: [],
    filteredOffices: [],
    filteredRoles: [],
    filteredStatuses: [],
    availabilityDate: undefined,
  });

  const [isConsultantUser, setIsConsultantUser] = useState(false);

  const { consultants, isLoading, isFetching } = useConsultants({
    searchQuery: searchQuery,
    locations: filters.filteredOffices,
    levels: filters.filteredLevels,
    colors: filters.filteredStatuses,
    roles: filters.filteredRoles,
    availabilityDate: filters.availabilityDate
      ? DateHelper.dateToEpochTimestamp(filters.availabilityDate)
      : undefined,
  });

  useEffect(() => {
    setIsConsultantUser(user && user.role === 'consultant');
  }, []);

  return (
    <div className='flex flex-col w-full h-screen py-8'>
      <Button
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className='fixed z-40 rounded-full bottom-4 right-4 md:hidden'
        size={'icon'}
      >
        <MoveUp />
      </Button>
      <div className='flex flex-col justify-between gap-4 md:flex-row'>
        <div className='flex flex-row items-center justify-between'>
          <h1 className='text-primary'>Consultants</h1>
          <Button size={'icon'} className='md:hidden' onClick={handleModalOpen}>
            <Plus />
          </Button>
        </div>
        <div className='flex flex-col-reverse gap-4 md:flex-row'>
          <Input
            className='md:w-[350px] flex-1 md:flex-none'
            leadingIcon={<Search className='w-4 h-4' />}
            value={debouncedSearchQuery}
            placeholder='Search name, skills or comments'
            onChange={(e) => setDebouncedSearchQuery(e.target.value)}
            clear={() => setDebouncedSearchQuery('')}
            tootip={
              <HoverCard>
                <HoverCardTrigger className='absolute top-0 items-center justify-center hidden h-full md:flex text-grey-300 right-2'>
                  <Info className='w-5 h-5' />
                </HoverCardTrigger>
                <HoverCardContent className='text-sm text-grey-300' align='end'>
                  Use /skill to search for specific skills. E.g. /skill react,
                  C#
                </HoverCardContent>
              </HoverCard>
            }
          />

          {!isConsultantUser && (
            <div className='hidden md:flex'>
              <CreateConsultantModal
                open={open}
                handleModalOpen={handleModalOpen}
              />
            </div>
          )}
        </div>
      </div>
      <Filters filters={filters} setFilters={setFilters} />
      <div className='flex flex-col items-center gap-4 overflow-y-scroll'>
        <Loader loading={isLoading} />
        {consultants &&
          !!consultants.length &&
          consultants.map((consultant) => {
            return (
              <ConsultantContext.Provider
                key={consultant.id}
                value={{ consultant, isConsultantUser }}
              >
                <ConsultantCard />
              </ConsultantContext.Provider>
            );
          })}
        {consultants && !consultants.length && 'No Consultants Found'}
      </div>
    </div>
  );
}

interface FiltersProps {
  filters: AvailableFilters;
  setFilters: (value: React.SetStateAction<AvailableFilters>) => void;
}
const Filters: React.FC<FiltersProps> = ({ filters, setFilters }) => {
  const { isMobile } = useScreenDetector();
  return (
    <div className='md:pt-12 pb-4'>
      {isMobile ? (
        <Accordion className='md:hidden' type='single' collapsible>
          <AccordionItem value='item-1'>
            <AccordionTrigger className=''>Filters</AccordionTrigger>
            <AccordionContent className='flex flex-row flex-wrap gap-6'>
              <ConsultantFilters filters={filters} setFilters={setFilters} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ) : (
        <div className='flex-row flex-wrap hidden gap-4 md:flex'>
          <ConsultantFilters filters={filters} setFilters={setFilters} />
        </div>
      )}
    </div>
  );
};

interface ConsultantFiltersProps {
  filters: AvailableFilters;
  setFilters: (value: React.SetStateAction<AvailableFilters>) => void;
}
const ConsultantFilters: React.FC<ConsultantFiltersProps> = ({
  filters,
  setFilters,
}) => {
  const { parsedOffices, isLoading: isLoadingOffices } = useOffices();
  const { parsedStatuses, isLoading: isLoadingStatuses } = useStatuses();
  const { parsedRoles, isLoading: isLoadingRoles } = useRoles();

  function applySelectedLevels(levels: string[]) {
    setFilters({ ...filters, filteredLevels: levels });
  }
  function handleSelectLevel(option: string) {
    if (!filters.filteredLevels.some((value) => value === option)) {
      setFilters((prev) => ({
        ...prev,
        filteredLevels: [...prev.filteredLevels, option],
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        filteredLevels: prev.filteredLevels.filter((value) => value !== option),
      }));
    }
  }
  function handleDeselectAllLevels() {
    setFilters({ ...filters, filteredLevels: [] });
  }

  function applySelectedStatuses(statuses: string[]) {
    setFilters({ ...filters, filteredStatuses: statuses });
  }
  function handleSelectColor(option: string) {
    if (!filters.filteredStatuses.some((value) => value === option)) {
      setFilters((prev) => ({
        ...prev,
        filteredStatuses: [...prev.filteredStatuses, option],
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        filteredStatuses: prev.filteredStatuses.filter(
          (value) => value !== option
        ),
      }));
    }
  }
  function handleDeselectAllColors() {
    setFilters({ ...filters, filteredStatuses: [] });
  }

  function applySelectedOffices(offices: string[]) {
    setFilters({ ...filters, filteredOffices: offices });
  }
  function handleSelectOffice(option: string) {
    if (!filters.filteredOffices.some((value) => value === option)) {
      setFilters((prev) => ({
        ...prev,
        filteredOffices: [...prev.filteredOffices, option],
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        filteredOffices: prev.filteredOffices.filter(
          (value) => value !== option
        ),
      }));
    }
  }
  function handleDeselectAllOffices() {
    setFilters({ ...filters, filteredOffices: [] });
  }

  function applySelectedRoles(roles: string[]) {
    setFilters({ ...filters, filteredRoles: roles });
  }
  function handleSelectRole(option: string) {
    if (!filters.filteredRoles.some((value) => value === option)) {
      setFilters((prev) => ({
        ...prev,
        filteredRoles: [...prev.filteredRoles, option],
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        filteredRoles: prev.filteredRoles.filter((value) => value !== option),
      }));
    }
  }
  function handleDeselectAllRoles() {
    setFilters({ ...filters, filteredRoles: [] });
  }

  const handleSelectAvailabilityDate = (date: Date | undefined) => {
    setFilters({ ...filters, availabilityDate: date ?? undefined });
  };

  function removeAllFilters() {
    setFilters({
      filteredLevels: [],
      filteredOffices: [],
      filteredRoles: [],
      filteredStatuses: [],
      availabilityDate: undefined,
    });
  }
  return (
    <>
      <Combobox
        selectedOptions={filters.filteredLevels}
        selectName='Levels'
        className='flex-1 xs:max-w-[200px]'
        handleDeselectAll={handleDeselectAllLevels}
        options={availableLevels}
        selectOption={handleSelectLevel}
        applyOptions={applySelectedLevels}
      />
      <Combobox
        selectedOptions={filters.filteredStatuses}
        useLabelAsSelected
        selectName='Status'
        className='flex-1 xs:max-w-[200px]'
        handleDeselectAll={handleDeselectAllColors}
        options={parsedStatuses ?? []}
        selectOption={handleSelectColor}
        applyOptions={applySelectedStatuses}
      />
      <Combobox
        selectedOptions={filters.filteredRoles}
        useLabelAsSelected
        selectName='Roles'
        className='flex-1 xs:max-w-[200px]'
        handleDeselectAll={handleDeselectAllRoles}
        options={parsedRoles ?? []}
        selectOption={handleSelectRole}
        applyOptions={applySelectedRoles}
      />
      <Combobox
        selectedOptions={filters.filteredOffices}
        selectName='Offices'
        className='flex-1 xs:max-w-[200px]'
        handleDeselectAll={handleDeselectAllOffices}
        options={parsedOffices ?? []}
        selectOption={handleSelectOffice}
        applyOptions={applySelectedOffices}
      />
      <DateSelector
        buttonLabel='Available since'
        date={filters.availabilityDate}
        selectDate={handleSelectAvailabilityDate}
        buttonClasses='flex-1 xs:max-w-[200px]'
      />
      {(filters.filteredStatuses.length !== 0 ||
        filters.filteredOffices.length !== 0 ||
        filters.filteredLevels.length !== 0 ||
        filters.availabilityDate) && (
        // ||
        // searchQuery !== ''
        <div className='w-full md:w-auto'>
          <Button
            variant={'underline'}
            onClick={removeAllFilters}
            className='space-x-2'
          >
            <p>Clear all</p>
            <Trash2 className='w-5 h-5' />
          </Button>
        </div>
      )}
    </>
  );
};

export default ConsultantsFinderPage;
