import React, { ReactNode, useState } from 'react';
import { useScreenDetector } from '../../utils/hooks/useScreenDetector';
import { Drawer, DrawerClose, DrawerContent, DrawerTrigger } from './drawer';
import { cn } from '../../utils/helpers';
import { Button } from './button';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

interface ResponsivePopoverProps {
  showClose?: boolean;
  triggerButtonChildren: ReactNode;
  triggerButtonClassName?: string;
  content: ReactNode;
  isOpen?: boolean;
  handleOpen?: () => void;
  dismiss?: () => void;
}

export const ResponsivePopover: React.FC<ResponsivePopoverProps> = ({
  showClose,
  triggerButtonChildren,
  triggerButtonClassName,
  content,
  isOpen,
  handleOpen,
  dismiss,
}) => {
  const [open, setOpen] = useState(false);
  const { isMobile } = useScreenDetector();
  return (
    <>
      {isMobile ? (
        <Drawer
          open={isOpen ?? open}
          onOpenChange={(opened) => {
            setOpen(opened);
            if (!opened) {
              dismiss && dismiss();
            }
          }}
          modal
        >
          <DrawerTrigger asChild className={cn('md:hidden')}>
            <Button
              variant='outline-neutral'
              aria-expanded={isOpen ?? open}
              onClick={handleOpen}
              className={cn(
                'min-w-[200px] relative hover:bg-white justify-between',
                triggerButtonClassName
              )}
            >
              {triggerButtonChildren}
            </Button>
          </DrawerTrigger>
          <DrawerContent className=''>
            {content}
            {showClose && (
              <DrawerClose asChild>
                <div className='flex items-center justify-center'>
                  <Button>Submit</Button>
                </div>
              </DrawerClose>
            )}
          </DrawerContent>
        </Drawer>
      ) : (
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild className='hidden md:flex'>
            <Button
              variant='outline-neutral'
              aria-expanded={open}
              className={cn(
                'min-w-[200px] relative hover:bg-white justify-between',
                triggerButtonClassName
              )}
            >
              {triggerButtonChildren}
            </Button>
          </PopoverTrigger>
          <PopoverContent className='min-w-[200px] w-[calc(100vw-32px)] xs:w-auto p-0 bg-white'>
            {content}
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};
