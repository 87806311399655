import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminRoute from '../components/general/admin-route.component';
import AuthRoute from '../components/general/auth-route.component';
import PrivateRoute from '../components/general/private-route.component';
import ConsultantsFinderPage from '../pages/consultants-finder-page';
import LoginPage from '../pages/login-page';
import ResetPasswordPage from '../pages/reset-password-page';
import SendPasswordResetLinkPage from '../pages/send-password-reset-page';
import SettingsPage from '../pages/settings-page';
import SignupPage from '../pages/signup-page';
import { OverviewPage } from '../pages/overview-page';
import { CurrentUserContext } from '../utils/contexts/current-user.context';

const RouteContainer = () => {
  const { getCurrentUser } = useContext(CurrentUserContext);
  const isAuthenticated = getCurrentUser();

  return (
    <Routes>
      <Route
        exact
        path='/'
        element={
          isAuthenticated ? (
            <Navigate to='/consultants' />
          ) : (
            <Navigate to='/login' />
          )
        }
      ></Route>
      <Route path='login' element={<AuthRoute component={LoginPage} />} />
      <Route path='signup' element={<AuthRoute component={SignupPage} />} />
      <Route
        path='send-password-reset'
        element={<AuthRoute component={SendPasswordResetLinkPage} />}
      />
      <Route
        path='reset-password'
        element={<AuthRoute component={ResetPasswordPage} />}
      />

      <Route
        path='overview'
        element={<PrivateRoute component={OverviewPage} />}
      />
      <Route
        path='consultants'
        element={
          <PrivateRoute noPadding={true} component={ConsultantsFinderPage} />
        }
      />

      <Route
        path='settings'
        element={<AdminRoute component={SettingsPage} />}
      />
    </Routes>
  );
};

export default RouteContainer;
