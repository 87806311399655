import React, { useState } from 'react';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Input } from '../ui/input';

import { Button } from '../ui/button';
import { RawRole } from '../../types';
import { useAddRole } from '../../utils/hooks/api/roles/useAddRole';

function AddNewRoleModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const [addRoleParams, setAddRoleParams] = useState<RawRole>({
    name: '',
  });
  const { mutate: addRole } = useAddRole();
  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className='max-w-screen-sm '>
        <DialogHeader>
          <DialogTitle>Add New Role</DialogTitle>
        </DialogHeader>

        <div className='flex flex-col gap-4'>
          <Input
            type='text'
            value={addRoleParams.name}
            placeholder='Name'
            onChange={(e) =>
              setAddRoleParams({ ...addRoleParams, name: e.target.value })
            }
          />

          <div className='flex items-center justify-center'>
            <Button
              onClick={() => addRole(addRoleParams, { onSuccess: handleClose })}
            >
              Add
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddNewRoleModal;
