import React, { useContext, useState } from 'react';
import { Button } from '../ui/button';
import { Edit, Palette, Pencil, Trash2 } from 'lucide-react';

import UpdateColorModal from './update-color-modal.component';
import useConfirm from '../ui/confirm-dialog';
import { Status } from '../../types';
import { useDeleteStatus } from '../../utils/hooks/api/statuses/useDeleteStatus';

function ColorsTableRow({ status }: { status: Status }) {
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const { confirm } = useConfirm();
  const { mutate: deleteStatus } = useDeleteStatus();

  const handleUpdateModal = () => {
    setUpdateModalOpen(!updateModalOpen);
  };

  const deleteColor = async function () {
    const hasConfirmed = await confirm({
      destructiveLabel: 'Delete',
      title: 'Delete status',
      description: 'Are you sure you want to delete this status?',
      cancelLabel: 'Cancel',
    });
    if (hasConfirmed) {
      deleteStatus(status.id);
    }
  };

  return (
    <div className='grid items-center grid-cols-1 gap-1 md:gap-4 md:grid-cols-2 '>
      <div className='flex flex-row items-center gap-4'>
        <div
          className='w-10 h-10 rounded-full'
          style={{ backgroundColor: `${status.RGBCode}` }}
        ></div>
        <p>{status.description ?? 'No description'}</p>
      </div>
      <div className='flex flex-row items-center w-full gap-4 md:justify-end'>
        {/* {color.isDefault ? (
          <p className='flex-1 px-4 text-end'>Default</p>
        ) : (
          <Button
            className='space-x-2'
            variant='link'
            onClick={handleDefaultColorOpen}
          >
            <Palette className='w-5 h-5' />
            <p>Set as default</p>
          </Button>
        )} */}
        <Button
          className='space-x-2'
          variant='link'
          onClick={handleUpdateModal}
        >
          <Pencil className='w-5 h-5' />
          <p>Edit</p>
        </Button>
        <Button className='space-x-2' variant='link' onClick={deleteColor}>
          <Trash2 className='w-5 h-5' />
          <p>Delete</p>
        </Button>
      </div>

      {/* <SetDefaultColorModal
        name={color.name}
        open={defaultColorOpen}
        handleClose={handleDefaultColorClose}
        onSubmit={setDefaultColor}
      /> */}
      <UpdateColorModal
        open={updateModalOpen}
        handleClose={handleUpdateModal}
        status={status}
      />
    </div>
  );
}

export default ColorsTableRow;
