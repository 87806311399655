import React from 'react';
import { cn } from '../../utils/helpers';
import { CalendarDays } from 'lucide-react';
import { Calendar } from './calendar';
import { ResponsivePopover } from './responsive-popover';

interface DateSelectorProps {
  buttonLabel: string;
  buttonClasses?: string;
  date: Date | undefined;
  selectDate: (date: Date | undefined) => void;
}

export const DateSelector: React.FC<DateSelectorProps> = ({
  buttonLabel,
  date,
  buttonClasses,
  selectDate,
}) => {
  return (
    <ResponsivePopover
      content={<Calendar mode='single' selected={date} onSelect={selectDate} />}
      triggerButtonChildren={
        <>
          <p className={cn('text-base')}>
            {date?.toLocaleDateString('sv-SE') ?? buttonLabel}
          </p>
          <CalendarDays />
        </>
      }
      showClose
      triggerButtonClassName={cn(
        'flex flex-row justify-between min-w-[200px] hover:bg-white space-x-2',
        !date ? 'text-grey-300' : 'text-black',
        buttonClasses
      )}
    />
  );
};
