import { useMutation, useQueryClient } from 'react-query';

import ConsultantService from '../../../services/consultant-service';

export const useDeleteConsultant = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation(
    ConsultantService.delete,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['consultants']);
      },
    }
  );
  return { mutate, isLoading, isError, error };
};
