import React, { useState } from 'react';
import { ConsultantDetails } from './consultant-details.component';
import { cn } from '../../utils/helpers';
import ConsultantModal from './consultant-modal.component';

function ConsultantCard() {
  const [editMode, setEditMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleModalOpen = () => {
    if (isOpen) {
      setEditMode(false);
    }
    setIsOpen(!isOpen);
  };

  const handleEditMode = async () => {
    setEditMode(!editMode);
  };

  return (
    <>
      <div
        className={cn(
          ' bg-white  rounded-lg w-full hover:scale-[1.02] cursor-pointer transition-all',
          editMode ? '' : ''
        )}
        onClick={handleModalOpen}
      >
        <ConsultantDetails
          handleEditMode={handleEditMode}
          handleModalOpen={handleModalOpen}
        />
      </div>
      <ConsultantModal
        open={isOpen}
        edit={editMode}
        handleModalOpen={handleModalOpen}
        handleEditMode={handleEditMode}
      />
    </>
  );
}

export default ConsultantCard;
