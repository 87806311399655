import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import pyyneLogo from '../../assets/images/logo-high-res-tree-only.png';
// @ts-ignore
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
// @ts-ignore
import UserService from '../../utils/services/user-service';
import { Button } from '../ui/button';

function LoginForm() {
  const [loginParams, setLoginInfo] = useState({ email: '', password: '' });
  // const history = useHistory()
  // @ts-ignore
  const { getAxios, login } = useContext(CurrentUserContext);

  const postLogin = async (loginParams: {
    email: string;
    password: string;
  }) => {
    const { success, user } = await UserService.login(getAxios(), loginParams);
    if (success) {
      await login(user);
      // history.push('/consultants')
    }
  };

  return (
    <div className='login-signup-form-container'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          postLogin(loginParams);
        }}
      >
        <img
          className='mx-auto login-form-logo'
          src={pyyneLogo}
          alt='PYYNE Logo'
        />
        <div className='form-input-container'>
          <input
            type='email'
            name='email'
            placeholder='Email'
            id='email'
            onChange={(e) =>
              setLoginInfo({ ...loginParams, email: e.target.value })
            }
            value={loginParams.email}
          />
        </div>
        <div className='form-input-container'>
          <input
            type='password'
            name='password'
            placeholder='Password'
            id='password'
            onChange={(e) =>
              setLoginInfo({ ...loginParams, password: e.target.value })
            }
            value={loginParams.password}
          />
        </div>
        <div className='form-submit-container'>
          <Button type='submit'>Login</Button>
        </div>
        <div className=''>
          <Button variant={'link'} to='/send-password-reset'>
            Forgot Password?
          </Button>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
