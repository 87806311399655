import React from 'react';
import '../../assets/styles/consultants-finder-page/availability-table.style.scss';
import { Loader } from '../ui/loader';
import { useAvailabilityOverview } from '../../utils/hooks/api/consultants/useAvailabilityOverview';

function AvailabilityTable() {
  const { availabilityOverview, isLoading } = useAvailabilityOverview();

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }
  if (!availabilityOverview) {
    return <div>Could not get availability overview</div>;
  }

  // Define the desired order of the color descriptions
  const desiredOrder = [
    'unavailable',
    'in talks with client',
    'close to engagement',
    'first engagement',
    'available',
  ];

  // Convert the colors object to an array
  const colorsArray = Object.entries(availabilityOverview?.colors || {});

  // Intermediate step to preserve original order for items not in desiredOrder
  const originalOrderMap: any = colorsArray.reduce(
    (acc: any, [color, _], index) => {
      acc[color] = index; // Use color as key for simplicity, assuming it's unique
      return acc;
    },
    {}
  );

  // Sort the colors array according to the rules
  const sortedColors = colorsArray.sort((a, b) => {
    const indexA = desiredOrder.indexOf(a[1].colorDescription.toLowerCase());
    const indexB = desiredOrder.indexOf(b[1].colorDescription.toLowerCase());

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    } else {
      return originalOrderMap[a[0]] - originalOrderMap[b[0]];
    }
  });

  return (
    <div className='max-w-2xl p-4 bg-white rounded md:p-8'>
      <Loader loading={isLoading} />
      <h3>Consultant Availability</h3>

      <div className='flex flex-col gap-4'>
        <div className='grid grid-cols-5 px-4 font-semibold text-center'></div>
        {sortedColors.map((color) => (
          <div
            key={color[0]}
            style={{
              backgroundColor:
                color[0] === '#000' ? '#B0B0B0' : color[0] + '30',
            }}
            className='px-4 py-2.5 rounded-full font-semibold text-center flex justify-between align-middle'
          >
            <>
              <div className='flex flex-row items-center col-span-2 gap-2'>
                <span
                  className='w-8 h-8 rounded-full'
                  style={{
                    backgroundColor: color[0],
                  }}
                />
                {color[1].colorDescription ?? 'Status description'}
              </div>
              <div className='text-lg'>{color[1].total} Consultants</div>
            </>
          </div>
        ))}
        <div className='flex justify-between px-4'>
          <div>TOTAL CONSULTANTS</div>
          <div>{availabilityOverview.sum.total}</div>
        </div>
      </div>
    </div>
  );
}

export default AvailabilityTable;
