import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useCreateStatus } from '../../utils/hooks/api/statuses/useCreateStatus';
import { RawStatus } from '../../types';

function AddNewColorModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const [createStatusParams, setCreateStatusParams] = useState<RawStatus>({
    name: '',
    RGBCode: '',
    description: '',
  });
  const { mutate: mutateCreateStatus } = useCreateStatus();
  const createStatus = () => {
    mutateCreateStatus(createStatusParams, {
      onSuccess: () => {
        handleClose();
      },
    });
  };
  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className='max-w-screen-sm '>
        <DialogHeader>
          <DialogTitle>Add New Status</DialogTitle>
        </DialogHeader>

        <div className='flex flex-col gap-4'>
          <Input
            type='text'
            value={createStatusParams.name}
            placeholder='Name'
            onChange={(e) =>
              setCreateStatusParams({
                ...createStatusParams,
                name: e.target.value,
              })
            }
          />
          <Input
            type='text'
            value={createStatusParams.description}
            placeholder='Description'
            onChange={(e) =>
              setCreateStatusParams({
                ...createStatusParams,
                description: e.target.value,
              })
            }
          />
          <Input
            type='color'
            id='head'
            name='head'
            value={createStatusParams.RGBCode}
            onChange={(e) =>
              setCreateStatusParams({
                ...createStatusParams,
                RGBCode: e.target.value,
              })
            }
          />

          <div className='flex items-center justify-center'>
            <Button onClick={createStatus}>Add</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddNewColorModal;
