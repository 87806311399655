import moment from 'moment';

export const dateFormats = {
  SLASH_FORMAT: 'MM/DD/YYYY',
  HTML_INPUT: 'YYYY-MM-DD',
};
const DateHelper = {
  dateToEpochTimestamp: (date: any) => {
    return Math.floor(new Date(date).getTime()) / 1000;
  },

  timestampToDateFormat: (timestamp: any, format = dateFormats.HTML_INPUT) => {
    return moment(timestamp * 1000)
      .utc()
      .format(format)
      .toString();
  },
};

export default DateHelper;
