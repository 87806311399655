import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { Link } from 'react-router-dom';
import { cn } from '../../utils/helpers';

const buttonVariants = cva(
  'inline-flex items-center bg-white justify-center rounded-md font-silka-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-1  focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary focus-visible:ring-black text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive focus-visible:ring-black  text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-primary text-primary hover:bg-primary-100 ',
        'outline-neutral':
          'border border-border text-grey  hover:bg-primary-100 ',
        secondary: 'bg-primary-100 text-primary hover:bg-primary-100/80',
        ghost: 'hover:bg-grey hover:text-accent-foreground',
        link: 'text-black bg-transparent underline-offset-4 hover:underline',
        'link-neutral':
          'text-black bg-transparent underline-offset-4 hover:underline',
        underline: 'text-black bg-transparent underline-offset-4 underline',
      },
      size: {
        default: 'h-11 md:h-10 px-4 py-2 min-w-[100px]',
        sm: 'h-9 rounded-md px-3',
        xs: 'h-4 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-11 w-11 md:h-10 md:w-10',
        'icon-small': 'h-6 w-6',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  to?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      to,
      children,
      disabled,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    if (variant === 'link' && !!to) {
      return (
        <Link
          className={cn(
            buttonVariants({ variant, size, className }),
            disabled && 'opacity-50'
          )}
          to={to}
        >
          {asChild ? <div>{children}</div> : children}
        </Link>
      );
    }
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          disabled && 'opacity-50'
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        {asChild ? <div>{children}</div> : children}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
