import React, { ReactNode, useEffect, useState } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { DateSelector } from '../ui/date-selector';
import { useRoles } from '../../utils/hooks/api/roles/useRoles';
import { Combobox } from '../ui/combo-box';
import { availableLevels } from '../../utils/helpers';
import { Checkbox } from '../ui/checkbox';
import useConfirm from '../ui/confirm-dialog';
import { useDeleteConsultant } from '../../utils/hooks/api/consultants/useDeleteConsultant';
import { useOffices } from '../../utils/hooks/api/offices/useOffices';
import { useStatuses } from '../../utils/hooks/api/statuses/useStatuses';
import { useConsultantContext } from '../../utils/contexts/consultant-context';
import DateHelper from '../../utils/helpers/date.helper';
import { useUpdateConsultant } from '../../utils/hooks/api/consultants/useUpdateConsultant';
import { ResponsiveSelect } from '../ui/responsive-select';

function ConsultantCardInfoUpdate({
  handleEditMode,
  handleModalOpen,
}: {
  handleEditMode: () => void;
  handleModalOpen: () => void;
}) {
  const { parsedOffices } = useOffices();
  const { parsedStatuses } = useStatuses();
  const { parsedRoles } = useRoles();
  const { confirm } = useConfirm();
  const { consultant } = useConsultantContext();
  const { mutate: deleteConsultant } = useDeleteConsultant();
  const [consultantUpdateInfo, setConsultantUpdateInfo] = useState({
    ...consultant,
    availabilityDate: DateHelper.timestampToDateFormat(
      consultant.availabilityDate
    ),
  });

  const finalizeDelete = async () => {
    const choice = await confirm({
      title: 'Delete consultant',
      description:
        'This will delete the information of this consultant permanently, you can’t undo this action. Are you sure?',
      destructiveLabel: 'Delete',
      cancelLabel: 'Cancel',
    });

    if (choice) {
      deleteConsultant(consultantUpdateInfo.id, {
        onSuccess: () => {
          handleEditMode();
        },
      });
    }
  };

  useEffect(() => {
    if (!parsedRoles) {
      return;
    }

    setConsultantUpdateInfo({
      ...consultantUpdateInfo,
      roles: parsedRoles
        .filter((role) => consultantUpdateInfo.roles?.includes(role.label))
        .map((role) => role.value),
    });
  }, [parsedRoles]);

  function handleDeselectAllRoles() {
    setConsultantUpdateInfo({
      ...consultantUpdateInfo,
      roles: [],
    });
  }
  function handleSelectRole(option: string) {
    if (
      !consultantUpdateInfo.roles?.some((value: string) => value === option)
    ) {
      setConsultantUpdateInfo({
        ...consultantUpdateInfo,
        roles: [...(consultantUpdateInfo.roles ?? []), option],
      });
    } else {
      setConsultantUpdateInfo({
        ...consultantUpdateInfo,
        roles: consultantUpdateInfo.roles.filter(
          (value: string) => value !== option
        ),
      });
    }
  }

  const { mutate: updateConsultant } = useUpdateConsultant();

  const saveConsultant = async () => {
    try {
      // @ts-ignore
      consultantUpdateInfo.location =
        consultantUpdateInfo.location.abbreviation;
      // @ts-ignore
      consultantUpdateInfo.color = consultantUpdateInfo.color.RGBCode;
      Object.keys(consultantUpdateInfo).forEach((key) => {
        // @ts-ignore
        if (consultantUpdateInfo[key] === null) {
          // @ts-ignore
          delete consultantUpdateInfo[key];
        }
      });
      const timestamp = DateHelper.dateToEpochTimestamp(
        consultantUpdateInfo.availabilityDate
      );
      if (!isNaN(timestamp)) {
        // @ts-ignore
        consultantUpdateInfo.availabilityDate = timestamp;
      }
      updateConsultant(consultantUpdateInfo, {
        onSuccess: (updatedConsultant) => {
          handleEditMode();
          setConsultantUpdateInfo({
            ...updatedConsultant,
            availabilityDate: DateHelper.timestampToDateFormat(
              updatedConsultant.availabilityDate
            ),
          });
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='space-y-8'>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8'>
        <div className='grid items-center grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1 '>
            <label className='font-semibold text-left'>Name:</label>
          </div>
          <Input
            className='col-span-5 md:col-span-4'
            type='text'
            value={consultantUpdateInfo.name}
            placeholder='Name'
            name='name'
            onChange={(e) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                name: e.target.value,
              })
            }
          />
        </div>

        <div className='grid grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1'>
            <label className='font-semibold text-left'>Level:</label>
          </div>

          <ResponsiveSelect
            name='Level'
            onValueChange={(option) => {
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                level: option,
              });
            }}
            options={availableLevels}
            value={consultantUpdateInfo.level}
            className='col-span-5 md:col-span-4'
          />
        </div>
        <div className='grid grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1 '>
            <label className='font-semibold text-left'>Status:</label>
          </div>
          <ResponsiveSelect
            name='Status'
            useLabelAsSelected
            onValueChange={(option) => {
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                color: {
                  RGBCode: option,
                  name: option,
                  description: option,
                },
              });
            }}
            options={parsedStatuses}
            value={consultantUpdateInfo.color.RGBCode}
            className='col-span-5 md:col-span-4'
          />
        </div>

        <div className='grid grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1'>
            <label className='font-semibold text-left'>Location:</label>
          </div>
          <ResponsiveSelect
            name='Office'
            onValueChange={(option) => {
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                location: {
                  abbreviation: option,
                  city: option,
                },
              });
            }}
            options={parsedOffices}
            value={consultantUpdateInfo.location.abbreviation}
            className='col-span-5 md:col-span-4'
          />
        </div>

        <div className='grid grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1'>
            <label className='font-semibold text-left'>Role:</label>
          </div>
          <Combobox
            selectedOptions={consultantUpdateInfo.roles}
            selectName='Roles'
            useLabelAsSelected
            className='col-span-5 md:col-span-4 '
            handleDeselectAll={handleDeselectAllRoles}
            options={parsedRoles}
            selectOption={handleSelectRole}
          />
        </div>

        <div className='grid grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-5 md:col-span-1'>
            <label className='font-semibold text-left'>Available since:</label>
          </div>
          <DateSelector
            buttonLabel='Available since'
            buttonClasses='col-span-5 md:col-span-4'
            date={new Date(consultantUpdateInfo.availabilityDate)}
            selectDate={(date) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                availabilityDate: date!.toLocaleDateString('sv-SE'),
              })
            }
          />
        </div>

        <div className='grid items-center grid-cols-5 gap-1 md:gap-4'>
          <div className='flex flex-row items-center col-span-1'>
            <label className='font-semibold text-left'>Clients:</label>
          </div>

          <Input
            className='col-span-5 md:col-span-4'
            type='text'
            defaultValue={consultantUpdateInfo.clients}
            placeholder='Enter clients'
            name='client'
            onChange={(e) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                clients: e.target.value,
              })
            }
          />
        </div>

        <div className='grid grid-cols-5 '>
          <div className='flex flex-row items-center col-span-5 md:col-span-1'>
            <label className='font-semibold text-left'>Leads:</label>
          </div>
          <Input
            className='col-span-5 md:col-span-4'
            type='text'
            defaultValue={consultantUpdateInfo.leads}
            placeholder='Enter leads'
            name='leads'
            onChange={(e) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                leads: e.target.value,
              })
            }
          />
        </div>

        <div className='grid grid-cols-10 md:col-span-2'>
          <div className='flex flex-row items-center col-span-2 md:col-span-1'>
            <label className='font-semibold text-left'>Skills:</label>
          </div>
          <Textarea
            className='col-span-10 md:col-span-9'
            defaultValue={consultantUpdateInfo.skills}
            onChange={(e) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                skills: e.target.value,
              })
            }
          />
        </div>

        <div className='grid grid-cols-10 md:col-span-2'>
          <div className='flex flex-row items-center col-span-2 md:col-span-1'>
            <label className='font-semibold text-left'>Comments:</label>
          </div>
          <Textarea
            className='col-span-10 md:col-span-9'
            defaultValue={consultantUpdateInfo.comments}
            onChange={(e) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                comments: e.target.value,
              })
            }
          />
        </div>

        <div className='flex flex-row items-center gap-2'>
          <Checkbox
            id='firstEngagement'
            defaultChecked={consultantUpdateInfo.firstEngagement}
            onCheckedChange={(checked) =>
              setConsultantUpdateInfo({
                ...consultantUpdateInfo,
                firstEngagement: !!checked.valueOf(),
              })
            }
          />
          <label
            htmlFor='firstEngagement'
            className='peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
          >
            Add "First engagement"
          </label>
        </div>

        <div className='flex flex-row items-center justify-between gap-4 md:col-span-2 '>
          <Button variant={'destructive'} onClick={finalizeDelete}>
            Delete Consultant
          </Button>
          <div className='fixed bottom-0 left-0 flex flex-row items-center justify-center w-full md:w-auto gap-4 p-4 bg-white md:drop-shadow-none md:bg-transparent drop-shadow-[0px_-1px_10px_#00000040] md:static'>
            <Button onClick={saveConsultant}>Save</Button>
            <Button onClick={handleModalOpen} variant={'outline'}>
              Cancel
            </Button>
          </div>
        </div>
        <div className='h-24 md:hidden' />
      </div>
    </div>
  );
}

export default ConsultantCardInfoUpdate;
