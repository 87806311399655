import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const availableLevels = [
  { label: 'Analyst', value: 'A' },
  { label: 'Associate Consultant', value: 'AC' },
  { label: 'Consultant', value: 'C' },
  { label: 'Senior Consultant', value: 'SRC' },
  { label: 'Associate Manager', value: 'AM' },
  { label: 'Manager', value: 'M' },
  { label: 'Senior Manager', value: 'SRM' },
  { label: 'Principal Engineer', value: 'PE' },
  { label: 'Partner', value: 'P' },
];

export const levelList: { [key: string]: { label: string; value: string } } = {
  A: { label: 'Analyst', value: 'A' },
  AC: { label: 'Associate Consultant', value: 'AC' },
  C: { label: 'Consultant', value: 'C' },
  SRC: { label: 'Senior Consultant', value: 'SRC' },
  AM: { label: 'Associate Manager', value: 'AM' },
  M: { label: 'Manager', value: 'M' },
  SRM: { label: 'Senior Manager', value: 'SRM' },
  PE: { label: 'Principal Engineer', value: 'PE' },
  P: { label: 'Partner', value: 'P' },
};
