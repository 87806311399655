import { useMutation, useQueryClient } from 'react-query';
import OfficeService from '../../../services/office-service';

export const useUpdateOffice = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation(
    OfficeService.update,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['offices']);
      },
    }
  );
  return { mutate, isLoading, isError, error };
};
