import { useMutation, useQueryClient } from 'react-query';
import RoleService from '../../../services/role-service';

export const useAddRole = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation(RoleService.add, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['roles']);
    },
  });
  return { mutate, isLoading, isError, error };
};
