import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import RoleService from '../../../services/role-service';
import { Role } from '../../../../types';
import { useEffect, useState } from 'react';

export const useRoles = () => {
  const {
    data: roles,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery<Role[], AxiosError>({
    queryKey: ['roles'],
    queryFn: RoleService.list,
  });
  const [parsedRoles, setParsedRoles] =
    useState<{ value: string; label: string }[]>();
  useEffect(() => {
    if (!roles) {
      return;
    }
    setParsedRoles(
      roles?.map((role) => {
        return {
          value: role.id,
          label: role.name,
        };
      })
    );
  }, [roles]);

  return {
    roles,
    parsedRoles,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
