import React from 'react'
import ResetPasswordComponent from '../components/login-signup-pages/reset-password-component'
import UserService from '../utils/services/user-service'

function ResetPasswordPage() {
  return (
    <div className='login-signup-content-container'>
      <ResetPasswordComponent ResetPassword={UserService.ResetPassword}/>
    </div>
  )
}

export default ResetPasswordPage