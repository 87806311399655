import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { cn } from '../../utils/helpers';
import { buttonVariants } from './button';

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  deliveryDays?: Date[];
};

const deliveryDayStyle = {
  border: `2px solid bg-purple-500`,
};

function Calendar({
  className,
  classNames,
  deliveryDays = [],
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3 mx-auto', className)}
      modifiers={{ booked: deliveryDays }}
      modifiersStyles={{ booked: deliveryDayStyle }}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-md font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline', size: 'icon' }),
          'h-10 w-10 bg-transparent p-0 opacity-50 hover:opacity-100'
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex items-center justify-center',
        head_cell:
          'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full items-center justify-center mt-2',
        cell: 'text-center text-md p-0 relative [&:has([aria-selected])]:bg-primary [&:has([aria-selected])]:rounded-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost', size: 'icon' }),
          "h-10 w-10 p-0 hover:bg-primary-100 hover:text-black rounded-md font-normal aria-selected:bg-primary aria-selected:text-primary-foreground aria-selected:opacity-100 after:content-['']"
        ),
        day_selected:
          'bg-primary rounded text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_today: 'bg-grey-100 text-black border border-grey rounded',
        day_outside: 'text-muted-foreground opacity-50',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft className='w-4 h-4' />,
        IconRight: ({ ...props }) => <ChevronRight className='w-4 h-4' />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
