import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
// @ts-ignore
import useQuery from '../../utils/hooks/useQuery';
import { Button } from '../ui/button';

function ResetPasswordComponent({ ResetPassword, Error }: any) {
  const [resetPasswordInfo, setResetPasswordInfo] = useState({
    password: '',
    passwordConfirmation: '',
  });

  // const history = useHistory()

  // @ts-ignore
  const { getAxios } = useContext(CurrentUserContext);

  let query = useQuery();

  const submitHandler = async (e: any) => {
    e.preventDefault();
    let passwordsWithToken = resetPasswordInfo;
    // @ts-ignore
    passwordsWithToken['token'] = query.get('token');
    const response = await ResetPassword(getAxios(), passwordsWithToken);
    if (response) {
      // history.push('/login')
    }
  };

  return (
    <div className='login-signup-form-container'>
      <form onSubmit={submitHandler}>
        <div className='form-inner'>
          <h2>Reset Password</h2>
          <div className='form-input-container'>
            <input
              type='password'
              name='password'
              id='password'
              placeholder='Password'
              onChange={(e) =>
                setResetPasswordInfo({
                  ...resetPasswordInfo,
                  password: e.target.value,
                })
              }
              value={resetPasswordInfo.password}
            />
          </div>
          <div className='form-input-container'>
            <input
              type='password'
              name='password-confirmation'
              id='password-confirmation'
              placeholder='Password Confirmation'
              onChange={(e) =>
                setResetPasswordInfo({
                  ...resetPasswordInfo,
                  passwordConfirmation: e.target.value,
                })
              }
              value={resetPasswordInfo.passwordConfirmation}
            />
          </div>
          <div className='form-submit-container'>
            <Button type='submit' variant={'default'}>
              Reset Password
            </Button>
          </div>
          <div className='form-group'>
            <Button variant={'link'} to='/login'>
              Have an account?
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ResetPasswordComponent;
