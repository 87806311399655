import { useMutation, useQueryClient } from 'react-query';

import StatusService from '../../../services/status-service';

export const useDeleteStatus = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation(
    StatusService.delete,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['statuses']);
      },
    }
  );
  return { mutate, isLoading, isError, error };
};
