import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Consultant } from '../../../../types';
import ConsultantService from '../../../services/consultant-service';

export const useConsultants = (listParams: {
  searchQuery: string;
  locations: string[];
  levels: string[];
  colors: string[];
  roles: string[];
  availabilityDate?: number;
}) => {
  const {
    data: consultants,
    isLoading,
    isRefetching,
    isSuccess,
    isFetching,
    isError,
    error,
  } = useQuery<Consultant[], AxiosError>({
    queryKey: [
      'consultants',
      listParams.searchQuery,
      listParams.locations,
      listParams.levels,
      listParams.colors,
      listParams.roles,
      listParams.availabilityDate,
    ],
    queryFn: () => ConsultantService.list(listParams),
  });

  return {
    consultants,
    isLoading,
    isRefetching,
    isFetching,
    isSuccess,
    isError,
    error,
  };
};
