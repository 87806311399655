import React from 'react';
import AvailabilityTable from '../components/consultants-finder-page/availability-table.component';

// interface OverviewPageProps {}

export const OverviewPage = () => {
  return (
    <div className=''>
      <h1 className='pb-16 text-primary'>Overview</h1>
      <AvailabilityTable />
    </div>
  );
};
