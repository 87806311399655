import { Check, ChevronDown, Command } from 'lucide-react';
import { Button } from './button';
import React, { useState } from 'react';
import { cn } from '../../utils/helpers';
import { ScrollArea } from './scroll-area';
import { ResponsivePopover } from './responsive-popover';
import { ValueAndLabel } from '../../types';
import { useScreenDetector } from '../../utils/hooks/useScreenDetector';

interface ComboboxProps {
  selectName?: string;
  className?: string;
  options: ValueAndLabel[] | undefined;
  selectedOptions?: string[];
  handleDeselectAll?: () => void;
  selectOption: (option: string) => void;
  useLabelAsSelected?: boolean;
  applyOptions?: (options: string[]) => void;
}
export const Combobox: React.FC<ComboboxProps> = ({
  selectName,
  options = [],
  className,
  selectedOptions,
  handleDeselectAll,
  selectOption,
  useLabelAsSelected,
  applyOptions,
}) => {
  // These are for mobile only, since we want the behavior to be different
  // on mobile we want to have a local store and apply all on confirm
  // On desktop, we want to apply the options as we select them
  const [isOpen, setOpen] = useState(false);
  function adjustedApplyOptions(options: string[]) {
    applyOptions && applyOptions(options);
    dismiss();
  }

  function adjustedDeselectAll() {
    handleDeselectAll && handleDeselectAll();
    dismiss();
  }
  function handleOpen() {
    setOpen(!isOpen);
  }
  function dismiss() {
    setOpen(false);
  }
  return (
    <>
      <ResponsivePopover
        isOpen={applyOptions && isOpen}
        handleOpen={handleOpen}
        dismiss={dismiss}
        content={
          <ComboboxContent
            selectedOptions={selectedOptions}
            handleDeselectAll={adjustedDeselectAll}
            selectOption={selectOption}
            options={options}
            applyOptions={applyOptions && adjustedApplyOptions}
          />
        }
        triggerButtonChildren={
          <ComboboxButton
            selectedOptions={selectedOptions}
            selectName={selectName}
            options={options}
            useLabelAsSelected={useLabelAsSelected}
          />
        }
        triggerButtonClassName={className}
      />
    </>
  );
};

interface ComboboxContentProps {
  options: { label: string; value: string }[];
  selectedOptions?: string[];
  handleDeselectAll?: () => void;
  selectOption: (option: string) => void;
  applyOptions?: (options: string[]) => void;
}
const ComboboxContent: React.FC<ComboboxContentProps> = ({
  options,
  handleDeselectAll,
  selectOption,
  selectedOptions,
  applyOptions,
}) => {
  const { isMobile } = useScreenDetector();
  const [localSelectedOptions, setLocalSelectedOptions] = useState(
    selectedOptions ?? []
  );
  function handleLocalSelect(option: string) {
    if (!localSelectedOptions.some((value) => value === option)) {
      setLocalSelectedOptions((prev) => [...prev, option]);
    } else {
      setLocalSelectedOptions((prev) =>
        prev.filter((value) => value !== option)
      );
    }
  }
  return (
    <ScrollArea maxHeight='max-h-[80vh]' className={cn('')}>
      <div className='flex flex-col justify-between py-2'>
        <div className='flex-1'>
          {options.map((option) => (
            <div
              onClick={() =>
                applyOptions && isMobile
                  ? handleLocalSelect(option.value)
                  : selectOption(option.value)
              }
              key={option.value}
              style={{ width: 'var(--radix-popover-trigger-width)' }}
              className={cn(
                'flex items-center gap-2 px-4 py-3 md:py-3 cursor-pointer flex-row justify-between hover:bg-primary-100 w-full'
              )}
            >
              <label
                htmlFor={option.value}
                className='flex-1 pb-[2px] overflow-hidden font-medium leading-none cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-ellipsis whitespace-nowrap'
              >
                {option.label}
              </label>
              <Check
                className={cn(
                  ' h-4 w-4 text-primary',
                  (
                    applyOptions && isMobile
                      ? localSelectedOptions?.some(
                          (selectedOption) => selectedOption === option.value
                        )
                      : selectedOptions?.some(
                          (selectedOption) => selectedOption === option.value
                        )
                  )
                    ? 'opacity-100'
                    : 'opacity-0'
                )}
              />
            </div>
          ))}
        </div>

        <div className='flex flex-row gap-4 px-4 pt-4'>
          <Button
            variant={'outline'}
            className='w-full'
            size={'sm'}
            onClick={handleDeselectAll}
          >
            Clear all
          </Button>
          {applyOptions && isMobile && (
            <Button
              className='w-full md:hidden'
              size={'sm'}
              onClick={() => applyOptions(localSelectedOptions)}
            >
              Confirm
            </Button>
          )}
        </div>
      </div>
    </ScrollArea>
  );
};

interface ComboboxButtonProps {
  selectName?: string;
  options: { label: string; value: string }[];
  selectedOptions?: string[];
  useLabelAsSelected?: boolean;
}
const ComboboxButton: React.FC<ComboboxButtonProps> = ({
  selectName,
  options,
  selectedOptions,
  useLabelAsSelected,
}) => {
  return (
    <>
      {selectedOptions?.length === 0 ? (
        <p className=' text-grey-300'>{selectName}</p>
      ) : (
        <div className='flex flex-row justify-start w-full h-full overflow-hidden text-black'>
          {selectedOptions?.map((option, index) => (
            <div key={option} className='whitespace-nowrap'>
              {/* If we the this prop, we want to show the label of that option instead of the
              value which is normally stored in the selectedOptions */}
              {useLabelAsSelected
                ? options
                    .find((availOption) => availOption.value === option)
                    ?.label.slice(0, 5) + '..'
                : option}
              {index !== selectedOptions?.length - 1 && ', '}
            </div>
          ))}
        </div>
      )}

      <ChevronDown className='w-6 h-6 ml-2 text-grey-300 shrink-0' />
      {!!selectedOptions?.length && selectedOptions?.length > 0 && (
        <div className='absolute right-0 flex items-center justify-center w-6 h-6 font-semibold rounded-full -top-4 text-primary bg-primary-100'>
          {selectedOptions?.length}
        </div>
      )}
    </>
  );
};
