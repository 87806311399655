import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import React from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import ConsultantCardInfoUpdate from './consultant-card-info-update.component';
import { cn } from '../../utils/helpers';
import { ConsultantDetails } from './consultant-details.component';
import { useConsultantContext } from '../../utils/contexts/consultant-context';

function ConsultantModal({
  open,
  handleModalOpen,
  edit,
  handleEditMode,
}: {
  open: boolean;
  handleModalOpen: () => void;
  edit: boolean;
  handleEditMode: () => void;
}) {
  const { consultant } = useConsultantContext();
  const [parent] = useAutoAnimate();
  return (
    <div ref={parent}>
      <Dialog open={open} onOpenChange={handleModalOpen}>
        <DialogContent
          className={cn(
            'h-dynamic-screen md:h-auto max-w-[100vw] md:max-w-screen-lg max-h-[90vh] overflow-y-scroll'
          )}
        >
          <DialogHeader className='flex flex-row items-center max-w-[80%] justify-between space-y-0'>
            <DialogTitle className='pb-4 text-left'>
              {edit
                ? 'Update information: ' + consultant.name
                : 'Consultant details'}
            </DialogTitle>
          </DialogHeader>
          {edit ? (
            <ConsultantCardInfoUpdate
              handleEditMode={handleEditMode}
              handleModalOpen={handleModalOpen}
            />
          ) : (
            <ConsultantDetails
              isModal
              handleEditMode={handleEditMode}
              handleModalOpen={handleModalOpen}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ConsultantModal;
