import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Status } from '../../../../types';
import StatusService from '../../../services/status-service';
import { useEffect, useState } from 'react';

export const useStatuses = () => {
  const {
    data: statuses,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useQuery<Status[], AxiosError>({
    queryKey: ['statuses'],
    queryFn: StatusService.list,
  });

  const [parsedStatuses, setParsedStatusess] =
    useState<{ value: string; label: string }[]>();
  useEffect(() => {
    if (!statuses) {
      return;
    }
    setParsedStatusess(
      statuses?.map((value) => {
        return {
          value: value.RGBCode,
          label: value.description,
        };
      })
    );
  }, [statuses]);

  return {
    statuses,
    parsedStatuses,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
