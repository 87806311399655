import { useMutation, useQueryClient } from 'react-query';
import RoleService from '../../../services/role-service';

export const useDeleteRole = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation(
    RoleService.delete,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['roles']);
      },
    }
  );
  return { mutate, isLoading, isError, error };
};
