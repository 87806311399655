import * as React from 'react';
import { cn } from '../../utils/helpers';
import { ReactNode } from 'react';
import { Info, X } from 'lucide-react';
import { Button } from './button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from './hover-card';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  leadingIcon?: ReactNode;
  clear?: () => void;
  tootip?: ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, leadingIcon, value, clear, tootip, ...props }, ref) => {
    return (
      <div className={cn('relative ', className)}>
        <input
          type={type}
          className={cn(
            'flex h-11 md:h-10 w-full rounded-md border-border px-3 py-2 bg-white ring-offset-ring  border file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50',
            leadingIcon && 'pl-8',
            tootip && 'pr-12',
            className
          )}
          ref={ref}
          value={value}
          {...props}
        />
        {leadingIcon && (
          <div className='absolute top-0 flex items-center justify-center h-full text-grey left-2'>
            {leadingIcon}
          </div>
        )}
        {clear && value !== '' && (
          <Button
            className={cn(
              'absolute top-0 flex items-center justify-center h-full text-grey-300',
              tootip ? 'right-8' : 'right-2'
            )}
            variant='link-neutral'
            size={'icon'}
            onClick={clear}
          >
            <X />
          </Button>
        )}
        {tootip}
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
