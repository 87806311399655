import React from 'react'
import SendPasswordResetLinkComponent from '../components/login-signup-pages/send-password-reset-component'
import UserService from '../utils/services/user-service'

function SendPasswordResetLinkPage() {
  return (
    <div className='login-signup-content-container'>
      <SendPasswordResetLinkComponent SendPasswordReset={UserService.sendPasswordReset}/>
    </div>
  )
}

export default SendPasswordResetLinkPage