import React, { useState } from 'react';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useUpdateOffice } from '../../utils/hooks/api/offices/useUpdateOffice';
import { Office, RawOffice } from '../../types';

function UpdateOfficeModal({
  open,
  handleClose,
  office,
}: {
  open: boolean;
  handleClose: () => void;
  office: Office;
}) {
  const [updateOfficeParams, setUpdateOfficeParams] = useState<RawOffice>({
    abbreviation: office.abbreviation,
    city: office.city,
    country: office.country,
  });
  const { mutate: mutateUpdateOffice } = useUpdateOffice();
  const updateOffice = async () => {
    mutateUpdateOffice(
      { id: office.id, ...updateOfficeParams },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };
  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className='max-w-screen-sm '>
        <DialogHeader>
          <DialogTitle>Update Office</DialogTitle>
        </DialogHeader>

        <div className='flex flex-col gap-4'>
          <Input
            type='text'
            value={updateOfficeParams.abbreviation}
            placeholder='Abbreviation'
            onChange={(e) =>
              setUpdateOfficeParams({
                ...updateOfficeParams,
                abbreviation: e.target.value,
              })
            }
          />
          <Input
            type='text'
            value={updateOfficeParams.city}
            placeholder='City'
            onChange={(e) =>
              setUpdateOfficeParams({
                ...updateOfficeParams,
                city: e.target.value,
              })
            }
          />
          <Input
            type='text'
            value={updateOfficeParams.country}
            placeholder='Country'
            onChange={(e) =>
              setUpdateOfficeParams({
                ...updateOfficeParams,
                country: e.target.value,
              })
            }
          />

          <div className='flex items-center justify-center'>
            <Button onClick={updateOffice}>Update Office</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateOfficeModal;
