import React, { useContext, useEffect, useState } from 'react';
import { PlusCircle } from 'lucide-react';
import { Button } from '../ui/button';
import { Loader } from '../ui/loader';
import AddNewColorModal from './add-new-color-modal.component';
import ColorsTableRow from './color-table-row.component';
import { useStatuses } from '../../utils/hooks/api/statuses/useStatuses';

function ColorSettings() {
  const { statuses, isLoading } = useStatuses();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleCreateModal = () => setCreateModalOpen(!createModalOpen);

  return (
    <>
      <Loader loading={isLoading} />

      <div className=''>
        <div className='h-full p-8 space-y-4 bg-white rounded-lg'>
          <h2>Statuses</h2>

          <div className='flex flex-col gap-6 md:gap-4'>
            {statuses && !!statuses.length ? (
              statuses.map((status) => {
                return <ColorsTableRow key={status.id} status={status} />;
              })
            ) : (
              <>{'No status Found'}</>
            )}
            <div>
              <Button
                className='mt-4 space-x-2'
                variant='link'
                onClick={handleCreateModal}
              >
                <PlusCircle />
                <p>Add new status</p>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <AddNewColorModal
        open={createModalOpen}
        handleClose={handleCreateModal}
      />
    </>
  );
}

export default ColorSettings;
