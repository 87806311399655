import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { Input } from '../ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Button } from '../ui/button';

function UpdateUserModal({
  open,
  handleClose,
  updateParams,
  setUpdateParams,
  onSubmit,
}: {
  open: boolean;
  handleClose: () => void;
  updateParams: {
    id: string;
    name: string;
    email: string;
    role: string;
  };
  setUpdateParams: (data: {
    id: string;
    name: string;
    email: string;
    role: string;
  }) => void;
  onSubmit: () => void;
}) {
  const roles = [
    { value: 'admin', label: 'Admin' },
    { value: 'consultant', label: 'Consultant' },
    { value: 'salesperson', label: 'Salesperson' },
  ];

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className='max-w-screen-sm '>
        <DialogHeader>
          <DialogTitle>Update User</DialogTitle>
        </DialogHeader>

        <div className='flex flex-col gap-4'>
          <Input
            type='text'
            value={updateParams.name}
            placeholder='Name'
            onChange={(e) =>
              setUpdateParams({ ...updateParams, name: e.target.value })
            }
          />
          <Input
            type='text'
            value={updateParams.email}
            placeholder='Email'
            onChange={(e) =>
              setUpdateParams({ ...updateParams, email: e.target.value })
            }
          />

          <Select
            value={updateParams.role}
            disabled
            onValueChange={(option) =>
              setUpdateParams({ ...updateParams, role: option })
            }
          >
            <SelectTrigger>
              <SelectValue placeholder='Select a role' />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {roles.map((role) => {
                  return (
                    <SelectItem key={role.value} value={role.value}>
                      {role.label}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>

          <div className='flex items-center justify-center'>
            <Button onClick={onSubmit}>Update</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateUserModal;
