import toast from 'react-hot-toast'

const UserService = {
    login: async (axiosInstance, loginParams) => {
        try {
            const response = await axiosInstance.post('/login', {
                email: loginParams.email,
                password: loginParams.password
            })
            return { success: true, message: 'Login Successfull', user: response.data }
        } catch (error) {
            let errorMessage
            if (error.response && error.response.data) {
                errorMessage = error.response.data.error
            } else {
                errorMessage = 'Connection failed'
            }
            toast.error(errorMessage)
            return {
                status: false,
                message: errorMessage,
                user: null
            }
        }
    },

    logout: async (axiosInstance) => {
        try {
            await axiosInstance.post('/logout')
            toast.success('Logout Success!')
            return { status: true, message: 'Logout Success' }
        } catch (error) {
            let errorMessage
            if (error.response && error.response.data) {
                errorMessage = error.response.data.error
            } else {
                errorMessage = 'Connection failed'
            }
            return {
                status: false,
                message: errorMessage
            }
        }
    },

    signup: async (axiosInstance, signupParams) => {
        try {
            await axiosInstance.post('/user', signupParams)
            toast.success('User Created!')
            return { status: true, message: 'User Created' }
        } catch (error) {
            let errorMessage
            if (error.response && error.response.data) {
                errorMessage = error.response.data.error
            } else {
                errorMessage = 'Connection failed'
            }
            toast.error(errorMessage)
            return {
                status: false,
                message: errorMessage
            }
        }
    },

    sendPasswordReset: async (axiosInstance, sendPasswordResetParams) => {
        try {
            await axiosInstance.put('/user/password-recovery', {
                email: sendPasswordResetParams.email,
            })
        } catch (error) {
            toast.error('error')
            return {
                status: 'Error',
                message: 'Something went wrong'
            }
        }
        toast.success('Recovery Email Sent')
        return { status: 'Success' }
    },

    ResetPassword: async (axiosInstance, resetPasswordParams) => {
        try {
            await axiosInstance.post('/user/password-recovery', {
                password: resetPasswordParams.password,
                passwordConfirmation: resetPasswordParams.passwordConfirmation,
                token: resetPasswordParams.token
            })
        } catch (error) {
            toast.error('error')
            return {
                status: 'Error',
                message: 'Something went wrong'
            }
        }
        toast.success('Password Successfully Reset')
        return { status: 'Success' }
    },

    list: async (axiosInstance, listParams) => {
      try {
          const response = await axiosInstance.get('/manage-users', {
              params: { ...listParams }
          })
          return { status: true, users: response.data }
      } catch (error) {
          let errorMessage
          if (error.response && error.response.data) {
              errorMessage = error.response.data.error
          } else {
              errorMessage = 'Connection failed'
          }
          toast.error(errorMessage)
          return {
              status: false,
              message: errorMessage,
              users: []
          }
      }
  },

  update: async (axiosInstance, updateParams) => {
    try {
        const response = await axiosInstance.put(`/user/${updateParams.id}`, updateParams)
        toast.success('User Updated')
        return { status: true, users: response.data }
    } catch (error) {
        let errorMessage
        if (error.response && error.response.data) {
            errorMessage = error.response.data.error
        } else {
            errorMessage = 'Connection failed'
        }
        toast.error(errorMessage)
        return {
            status: false,
            message: errorMessage,
            users: []
        }
    }
  },

  delete: async (axiosInstance, id) => {
    try {
      await axiosInstance.delete(`/user/${id}`)
      return { status: true, message: 'User successfully deleted' }
    } catch (error) {
        let errorMessage
        if (error.response && error.response.data) {
            errorMessage = error.response.data.error
        } else {
            errorMessage = 'Connection failed'
        }
        toast.error(errorMessage)
        return {
            status: false,
            message: errorMessage
        }
    }
  },

  invite: async (axiosInstance, inviteUserParams) => {
    try {
        await axiosInstance.post('/invite-user', {
            name: inviteUserParams.name,
            email: inviteUserParams.email,
            role: inviteUserParams.role
        })
    } catch (error) {
        toast.error('error')
        return {
            status: 'Error',
            message: 'Something went wrong'
        }
    }
    toast.success('User Invited!')
    return { status: 'Success' }
  },

  resendInvite: async (axiosInstance, email) => {
    try {
        await axiosInstance.put('/invite-user', { email: email })
    } catch (error) {
        toast.error('error')
        return {
            status: 'Error',
            message: 'Something went wrong'
        }
    }
    toast.success('Invite Email Resent!')
    return { status: 'Success' }
  }
}

export default UserService
