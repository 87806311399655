import React from 'react';
import { Consultant } from '../../types';

export const ConsultantContext = React.createContext<{
  consultant: Consultant | undefined;
  isConsultantUser: boolean | undefined;
}>({ consultant: undefined, isConsultantUser: undefined });

export function useConsultantContext() {
  const { consultant, isConsultantUser } = React.useContext(ConsultantContext);
  if (consultant === undefined) {
    throw new Error(
      'useConsultantContext must be used within a ConsultantContext Provider'
    );
  }
  return { consultant, isConsultantUser };
}
