import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './dialog';
import { Button } from './button';

export const ConfirmContext = createContext<{
  confirm: (data: Omit<ConfirmDialogProps, 'isOpen'>) => Promise<boolean>;
}>({
  confirm: (data: Omit<ConfirmDialogProps, 'isOpen'>) => {
    return new Promise(() => {});
  },
});

export function useConfirmContext() {
  const { confirm } = useContext(ConfirmContext);
  if (confirm === undefined) {
    throw new Error(
      'useConfirmContext must be used within a ConfirmContext Provider'
    );
  }
  return { confirm };
}

type ConfirmDialogProps = {
  title?: string;
  description?: string;
  isOpen: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  destructiveLabel?: string;
};
export function ConfirmDialogProvider({ children }: any) {
  const [state, setState] = useState<ConfirmDialogProps>({
    isOpen: false,
    confirmLabel: 'Confirm',
  });
  const fn = useRef(null);

  const confirm = useCallback(
    (data: Omit<ConfirmDialogProps, 'isOpen'>) => {
      return new Promise<boolean>((resolve) => {
        setState({ ...data, isOpen: true });
        // @ts-ignore
        fn.current = (choice: boolean) => {
          resolve(choice);
          setState({ ...data, isOpen: false });
        };
      });
    },
    [setState]
  );

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      <Dialog
        open={state.isOpen}
        onOpenChange={
          // @ts-ignore
          () => fn.current(false)
        }
      >
        <DialogContent className='max-w-screen-sm '>
          <DialogHeader>
            {state.title && <DialogTitle>{state.title}</DialogTitle>}
            {state.description && (
              <DialogDescription>{state.description}</DialogDescription>
            )}
          </DialogHeader>
          <div className='flex flex-row items-center justify-center w-full gap-4 pt-8'>
            {state.confirmLabel && (
              <Button
                onClick={
                  // @ts-ignore
                  () => fn.current(true)
                }
              >
                {state.confirmLabel}
              </Button>
            )}
            {state.destructiveLabel && (
              <Button
                variant={'destructive'}
                onClick={
                  // @ts-ignore
                  () => fn.current(true)
                }
              >
                {state.destructiveLabel}
              </Button>
            )}
            {state.cancelLabel && (
              <Button
                variant={'outline'}
                onClick={
                  // @ts-ignore
                  () => fn.current(false)
                }
              >
                {state.cancelLabel}
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </ConfirmContext.Provider>
  );
}

export default function useConfirm() {
  /*
     This hook can be used to programmatically start a confirm dialog with some custom inputs
     Once imported, you can get the "confirm" method from the hook
     const { confirm } = useConfirm();
     With that method, you can simply call it on whatever action you want to have confirmed and use the 
     hasConfirmed boolean to check the result. 
     
     const hasConfirmed = await confirm({
        destructiveLabel: 'Delete',
        title: 'Delete user',
        description: 'Are you sure you want to delete this user?',
        cancelLabel: 'Cancel',
    });
    if (hasConfirmed) {
        // Your action that needs confirmation
    }
    */
  return useContext(ConfirmContext);
}
