import React, { useState } from 'react';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useCreateOffice } from '../../utils/hooks/api/offices/useCreateOffice';
import { RawOffice } from '../../types';

function AddNewOfficeModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const { mutate: mutateCreateOffice } = useCreateOffice();
  const [addOfficeParams, setAddOfficeParams] = useState<RawOffice>({
    abbreviation: '',
    city: '',
    country: '',
  });
  const createOffice = () => {
    mutateCreateOffice(addOfficeParams, {
      onSuccess: () => {
        setAddOfficeParams({
          abbreviation: '',
          city: '',
          country: '',
        });
        handleClose();
      },
    });
  };
  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className='max-w-screen-sm '>
        <DialogHeader>
          <DialogTitle>Add New Office</DialogTitle>
        </DialogHeader>

        <div className='flex flex-col gap-4'>
          <Input
            type='text'
            value={addOfficeParams.abbreviation}
            placeholder='Abbreviation'
            onChange={(e) =>
              setAddOfficeParams({
                ...addOfficeParams,
                abbreviation: e.target.value,
              })
            }
          />
          <Input
            type='text'
            value={addOfficeParams.city}
            placeholder='City'
            onChange={(e) =>
              setAddOfficeParams({ ...addOfficeParams, city: e.target.value })
            }
          />
          <Input
            type='text'
            value={addOfficeParams.country}
            placeholder='Country'
            onChange={(e) =>
              setAddOfficeParams({
                ...addOfficeParams,
                country: e.target.value,
              })
            }
          />

          <div className='flex items-center justify-center'>
            <Button onClick={createOffice}>Add</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddNewOfficeModal;
