import React from "react";
import "../assets/styles/login-signup/login-signup-pages.styles.scss";
import LoginForm from "../components/login-signup-pages/login-form.component";
import landing from "../assets/images/landingBg.webp";

function LoginPage() {
  return (
    <div className="login-signup-content-container">
      <img src={landing} className="login-bg" alt="A very cool penguin!" />
      <LoginForm />
    </div>
  );
}

export default LoginPage;
