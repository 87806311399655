import React, { useState, useContext } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import pyyneLogo from '../../assets/images/logo-high-res-tree-only.png';
import UserService from '../../utils/services/user-service';
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
import useQuery from '../../utils/hooks/useQuery';

function SignupForm() {
  const [signupInfo, setSignupInfo] = useState({
    name: '',
    password: '',
    passwordConfirmation: '',
    token: '',
  });
  let query = useQuery();
  // const history = useHistory()

  const { getAxios } = useContext(CurrentUserContext);
  const signup = async (signupParams) => {
    if (signupInfo.password !== signupInfo.passwordConfirmation) {
      toast.error('Password must be equal to Password Confirmation');
      return false;
    }
    const { status } = await UserService.signup(getAxios(), signupParams);
    return status;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let signupInfoWithToken = signupInfo;
    signupInfoWithToken['token'] = query.get('invite_token');
    const response = await signup(signupInfoWithToken);
    if (response) {
      // history.push('/login')
    }
    // history.push('/login')
  };

  return (
    <div className='login-signup-form-container'>
      <form onSubmit={submitHandler}>
        <img src={pyyneLogo} alt='PYYNE Logo' />
        <div className='form-inner'>
          <div className='form-input-container'>
            <input
              type='text'
              name='name'
              id='name'
              placeholder='Name'
              onChange={(e) =>
                setSignupInfo({ ...signupInfo, name: e.target.value })
              }
              value={signupInfo.name}
            />
          </div>
          <div className='form-input-container'>
            <input
              type='password'
              name='password'
              id='password'
              placeholder='Password'
              onChange={(e) =>
                setSignupInfo({ ...signupInfo, password: e.target.value })
              }
              value={signupInfo.password}
            />
          </div>
          <div className='form-input-container'>
            <input
              type='password'
              name='password-confirmation'
              id='password-confirmation'
              placeholder='Password Confirmation'
              onChange={(e) =>
                setSignupInfo({
                  ...signupInfo,
                  passwordConfirmation: e.target.value,
                })
              }
              value={signupInfo.passwordConfirmation}
            />
          </div>
          <div className='form-submit-container'>
            <input className='submit-button' type='submit' value='REGISTER' />
          </div>
          <div className='form-group'>
            Have an account? <Link to='/login'>Login</Link>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SignupForm;
