import { Button } from '../ui/button';
import { useContext, useEffect, useState } from 'react';
import { PlusCircle } from 'lucide-react';
import React from 'react';
import OfficesTableRow from './offices-table-row.component';
import AddNewOfficeModal from './add-new-office-modal.component';
import { Loader } from '../ui/loader';
import { useOffices } from '../../utils/hooks/api/offices/useOffices';

function OfficeSettings() {
  const { offices, isLoading } = useOffices();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleCreateModal = () => {
    setCreateModalOpen(!createModalOpen);
  };

  return (
    <div className='p-8 space-y-4 bg-white rounded-lg'>
      <Loader loading={isLoading} />
      <h2>Offices</h2>
      <div className='flex flex-row flex-wrap items-stretch w-full gap-4'>
        {offices && offices.length > 0 ? (
          <>
            {offices.map((office) => {
              return <OfficesTableRow key={office.id} office={office} />;
            })}

            <div className='flex flex-col items-center justify-center flex-1 rounded shadow bg-grey-100'>
              <Button
                variant='link'
                className='w-full h-full space-x-2 bg-grey-100'
                onClick={handleCreateModal}
              >
                <PlusCircle />
                <p>Add New Office</p>
              </Button>
            </div>
          </>
        ) : (
          <>{'No Offices Found'}</>
        )}
      </div>

      <AddNewOfficeModal
        open={createModalOpen}
        handleClose={handleCreateModal}
      />
    </div>
  );
}

export default OfficeSettings;
