import React, { useContext } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
import Sidebar from './sidebar';

const AdminRoute = ({ component: Component, ...rest }) => {
  const { getCurrentUser } = useContext(CurrentUserContext);

  const user = getCurrentUser();
  if (!user || user.role !== 'admin') {
    return <Navigate to='/login' />;
  }
  return (
    <div className='flex-row lg:flex'>
      <Sidebar />
      <div className='w-full max-w-screen-xl px-4 py-8 mx-auto'>
        <Component />
      </div>
    </div>
  );
};

export default AdminRoute;
